<template>
  <div class="home">
    Welcome to the Wasatch Wiki
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'wiki'
}
</script>
